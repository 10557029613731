module.exports = [
  {
    "name": "Leela Yoga Studio",
    "website": "leelayogaalameda.com",
    "email": "leelayogainfo@gmail.com",
    "city": "Alameda",
    "phone": "312-952-8531",
    "state":"California"
  },
  {
    "name": "Yoga Within",
    "website": "yogawithin.com",
    "email": "ameyfm@yahoo.com",
    "city": "Aptos",
    "phone": "831-687-0818",
    "state":"California"
  },
  {
    "name": "Samsara Wellness Center",
    "website": "samsarawellnesscenter.com",
    "email": "info@samsarawellnesscenter.com",
    "city": "Bakersfield",
    "phone": "661-374-8693",
    "state":"California"
  },
  {
    "name": "Warrior 1 Yoga",
    "website": "warrior1yogaspa.com",
    "email": "warrior1yoga@icloud.com",
    "city": "Bakersfield",
    "phone": "661-319-6906",
    "state":"California"
  },
  {
    "name": "Yoga House Co. • Benicia",
    "website": "beniciayogahouse.com",
    "email": "tiana@beniciayogahouse.com",
    "city": "Benicia",
    "phone": "707-297-6769",
    "state":"California"
  },
  {
    "name": "4th Street Yoga",
    "website": "4thstreetyoga.com",
    "email": "yoga@4thstreetyoga.com",
    "city": "Berkeley",
    "phone": "510-845-9642",
    "state":"California"
  },
  {
    "name": "Ma Yoga Beverly Hills - BHCMG",
    "website": "mayoga.com",
    "email": "jessica@mayoga.com",
    "city": "Beverly Hills",
    "phone": "323-680-5355",
    "state":"California"
  },
  {
    "name": "Unfold Yoga OC",
    "website": "unfoldyogaoc.com",
    "email": "april@unfoldyogaoc.com",
    "city": "Brea",
    "phone": "714-455-9642",
    "state":"California"
  },
  {
    "name": "Brentwood Yoga Center",
    "website": "brentwoodyogacenter.com",
    "email": "YogaSandy@rocketmail.com",
    "city": "Brentwood",
    "phone": "925-240-6864",
    "state":"California"
  },
  {
    "name": "Yoga Blend",
    "website": "yogablend.com",
    "email": "info@yogablend.com",
    "city": "Burbank",
    "phone": "818-954-9642",
    "state":"California"
  },
  {
    "name": "Camarillo Yoga Center",
    "website": "camarilloyoga.com",
    "email": "audrey@camarilloyoga.com",
    "city": "Camarillo",
    "phone": "805-484-8810",
    "state":"California"
  },
  {
    "name": "The Yoga Studio",
    "website": "theyogastudio.biz",
    "email": "info@theyogastudio.biz",
    "city": "Campbell",
    "phone": "408-634-9642",
    "state":"California"
  },
  {
    "name": "Seaside Yoga Sanctuary",
    "website": "seasideyogasanctuary.com",
    "email": "seasideyoga@sbcglobal.net",
    "city": "Carmel-By-The-Sea",
    "phone": "831-899-9642",
    "state":"California"
  },
  {
    "name": "Claremont Yoga",
    "website": "claremontyoga.com",
    "email": "claremontyoga1@gmail.com",
    "city": "Claremont",
    "phone": "909-563-0714",
    "state":"California"
  },
  {
    "name": "The Yoga Unit",
    "website": "theyogaunit.com",
    "email": "info@theyogaunit.com",
    "city": "Claremont",
    "phone": "909-624-4800",
    "state":"California"
  },
  {
    "name": "Old Town Yoga - Clovis",
    "website": "oldtownyogastudio.com",
    "email": "info@oldtownyogastudio.com",
    "city": "Clovis",
    "phone": "559-472-6677",
    "state":"California"
  },
  {
    "name": "Yoga Sol",
    "website": "yogasol.net",
    "email": "info@yogasol.net",
    "city": "Concord",
    "phone": "925-288-9642",
    "state":"California"
  },
  {
    "name": "Just Yoga",
    "website": "justyogaonline.com",
    "email": "info@justyogaonline.com",
    "city": "Corona",
    "phone": "951-736-9642",
    "state":"California"
  },
  {
    "name": "Yoga Center Of Marin",
    "website": "yogacenterofmarin.com",
    "email": "info@yogacenterofmarin.com",
    "city": "Corte Madera",
    "phone": "415-927-1850",
    "state":"California"
  },
  {
    "name": "Barefoot Yoga Studio",
    "website": "barefootyogadavis.com",
    "email": "info@barefootyogadavis.com",
    "city": "Davis",
    "phone": "530-753-9642",
    "state":"California"
  },
  {
    "name": "Downey Yoga",
    "website": "downeyyoga.com",
    "email": "info@downeyyoga.com",
    "city": "Downey",
    "phone": "562-336-4741",
    "state":"California"
  },
  {
    "name": "Dragonfly Yoga Dublin || Connect. Breathe. Live.",
    "website": "dragonflyyogadublin.com",
    "email": "info@dragonflyyogadublin.com",
    "city": "Dublin",
    "phone": "925-580-1716",
    "state":"California"
  },
  {
    "name": "Square One Yoga El Cerrito",
    "website": "squareoneyoga.com",
    "email": "info@squareoneyoga.com",
    "city": "El Cerrito",
    "phone": "510-406-9048",
    "state":"California"
  },
  {
    "name": "happy baby",
    "website": "happybabyla.com",
    "email": "erin@happybabyla.com",
    "city": "El Segundo",
    "phone": "424-218-9774",
    "state":"California"
  },
  {
    "name": "Soul of Yoga",
    "website": "soulofyoga.com",
    "email": "shannon@soulofyoga.com",
    "city": "Encinitas",
    "phone": "760-943-7685",
    "state":"California"
  },
  {
    "name": "Inner Phoenix Yoga",
    "website": "innerphoenixyoga.com",
    "email": "admin@innerphoenixyoga.com",
    "city": "Fontana",
    "phone": "909-477-9269",
    "state":"California"
  },
  {
    "name": "Worlds Yoga Fremont",
    "website": "fremont.worldsyoga.com",
    "email": "info@worldsyoga.com",
    "city": "Fremont",
    "phone": "510-796-9642",
    "state":"California"
  },
  {
    "name": "Perfect Balance Yoga",
    "website": "pbyoga.com",
    "email": "joanne@pbyoga.com",
    "city": "Fresno",
    "phone": "559-222-6212",
    "state":"California"
  },
  {
    "name": "COIL Yoga",
    "website": "coilyoga.com",
    "email": "kflinn@coilyoga.com",
    "city": "Fresno",
    "phone": "559-353-2336",
    "state":"California"
  },
  {
    "name": "Fig Garden Yoga Studio",
    "website": "figgardenyogastudio.com",
    "email": "info@figgardenyogastudio.com",
    "city": "Fresno",
    "phone": "559-222-5100",
    "state":"California"
  },
  {
    "name": "Sync Yoga + Cycle",
    "website": "syncla.com",
    "email": "info@syncla.com",
    "city": "Glendale",
    "phone": "818-696-1610",
    "state":"California"
  },
  {
    "name": "Glendale Yoga",
    "website": "glendaleyoga.com",
    "email": "glendaleyoga@sbcglobal.net",
    "city": "Glendale",
    "phone": "818-956-1621",
    "state":"California"
  },
  {
    "name": "Prema Yoga",
    "website": "premayogaflow.com",
    "email": "christine@premayogaflow.com",
    "city": "Granada Hills",
    "phone": "818-360-4060",
    "state":"California"
  },
  {
    "name": "Wild Mountain Yoga Center",
    "website": "wildmtyoga.com",
    "email": "amanda@wildmtyoga.com",
    "city": "Grass Valley",
    "phone": "530-265-4072",
    "state":"California"
  },
  {
    "name": "Hanford Yoga & Pilates",
    "website": "hanfordyoga.com",
    "email": "hanfordyoga@gmail.com",
    "city": "Hanford",
    "phone": "559-825-7410",
    "state":"California"
  },
  {
    "name": "Yoga Remedy's Essential Wellness",
    "website": "yogaremedys.com",
    "email": "eieiyoga@gmail.com",
    "city": "Harbor City",
    "phone": "424-337-9642",
    "state":"California"
  },
  {
    "name": "LYFE Yoga",
    "website": "lyfeyoga.com",
    "email": "info@lyfeyoga.com",
    "city": "Hermosa Beach",
    "phone": "310-374-5933",
    "state":"California"
  },
  {
    "name": "Yoga Shakti",
    "website": "yogashaktistudio.com",
    "email": "service@yogashaktistudio.com",
    "city": "Irvine",
    "phone": "949-856-9642",
    "state":"California"
  },
  {
    "name": "Jennica Joyce Prenatal Yoga",
    "website": "jennicajoyceyoga.com",
    "email": "jennicajoyceyoga@gmail.com",
    "city": "Joshua Tree",
    "phone": "760-833-1250",
    "state":"California"
  },
  {
    "name": "Prana Yoga Center",
    "website": "prana-yoga.com",
    "email": "alex@prana-yoga.com",
    "city": "La Jolla",
    "phone": "858-456-2806",
    "state":"California"
  },
  {
    "name": "Buddhi Yoga",
    "website": "buddhiyogalj.com",
    "email": "info@buddhiyogalj.com",
    "city": "La Jolla",
    "phone": "858-886-7580",
    "state":"California"
  },
  {
    "name": "FreeSpirit Yoga",
    "website": "freespirityoga.com",
    "email": "info@freespirityoga.com",
    "city": "Long Beach",
    "phone": "562-989-9959",
    "state":"California"
  },
  {
    "name": "Kava Yoga",
    "website": "kavayoga.com",
    "email": "cindy@kavayoga.com",
    "city": "Long Beach",
    "phone": "562-434-8888",
    "state":"California"
  },
  {
    "name": "Lotus Blossom Prenatal Yoga",
    "website": "lotusblossomprenatalyoga.com",
    "email": "lotusblossomprenatalyoga@gmail.com",
    "city": "Los Altos",
    "phone": "408-390-1894",
    "state":"California"
  },
  {
    "name": "Yoga of Los Altos",
    "website": "yogaoflosaltos.com",
    "email": "studio@yogaoflosaltos.com",
    "city": "Los Altos",
    "phone": "650-397-9779",
    "state":"California"
  },
  {
    "name": "MOMS AT OM • Pregnancy Yoga Studio",
    "website": "momsatom.la",
    "email": "hello@momsatom.la",
    "city": "Los Angeles",
    "phone": "424-228-2733",
    "state":"California"
  },
  {
    "name": "Silverlake Yoga",
    "website": "silverlakeyoga.com",
    "email": "info@silverlakeyoga.com",
    "city": "Los Angeles",
    "phone": "323-953-0496",
    "state":"California"
  },
  {
    "name": "Prenatal Barre Yoga",
    "website": "fitbumpbarre.com",
    "email": "info@prenatalbarreyoga.com",
    "city": "Los Angeles",
    "phone": "310-752-4611",
    "state":"California"
  },
  {
    "name": "Ma Yoga Living",
    "website": "mayogaliving.com",
    "email": "teachertraining@mayogaliving.com",
    "city": "Los Angeles",
    "phone": "323-680-5355",
    "state":"California"
  },
  {
    "name": "Zooga Yoga West Hollywood",
    "website": "zoogayoga.com",
    "email": "Info@zoogawesthollywood.com",
    "city": "Los Angeles",
    "phone": "323-847-5084",
    "state":"California"
  },
  {
    "name": "Radha Yoga",
    "website": "radhayogala.com",
    "email": "radhayogala@gmail.com",
    "city": "Los Angeles",
    "phone": "323-544-3861",
    "state":"California"
  },
  {
    "name": "YogaWorks Mill Valley",
    "website": "yogaworks.com",
    "email": "jaynez@yogaworks.com",
    "city": "Mill Valley",
    "phone": "415-318-7650",
    "state":"California"
  },
  {
    "name": "Grace Yoga Studio",
    "website": "grace-yoga.net",
    "email": "info@grace-yoga.net",
    "city": "Millbrae",
    "phone": "650-652-3405",
    "state":"California"
  },
  {
    "name": "Village Yoga Center",
    "website": "villageyogacenter.com",
    "email": "debyogajourney@yahoo.com",
    "city": "Modesto",
    "phone": "209-578-5441",
    "state":"California"
  },
  {
    "name": "Om Studios",
    "website": "omoasis.com",
    "email": "sarah@omoasis.com",
    "city": "Monterey",
    "phone": "831-214-9405",
    "state":"California"
  },
  {
    "name": "Union Yoga",
    "website": "unionyoga-monterey.com",
    "email": "hello@unionyoga-monterey.com",
    "city": "Monterey",
    "phone": "203-832-6854",
    "state":"California"
  },
  {
    "name": "California Yoga Center",
    "website": "californiayoga.com",
    "email": "info@californiayoga.com",
    "city": "Mountain View",
    "phone": "650-967-5702",
    "state":"California"
  },
  {
    "name": "Xplore Yoga",
    "website": "xploreyoga.com",
    "email": "teamx@xploreyoga.com",
    "city": "Newark",
    "phone": "510-896-8134",
    "state":"California"
  },
  {
    "name": "The Yoga Center",
    "website": "theyogacenter.net",
    "email": "info@theyogacenter.net",
    "city": "North Augusta",
    "phone": "803-613-0684",
    "state":"California"
  },
  {
    "name": "Barefoot Movement",
    "website": "barefootmovement.com",
    "email": "info@barefootmovement.com",
    "city": "Oakland",
    "phone": "510-550-5257",
    "state":"California"
  },
  {
    "name": "The Yoga Mat",
    "website": "theyogamatoc.com",
    "email": "louise@theyogamatoc.com",
    "city": "Orange",
    "phone": "714-403-1550",
    "state":"California"
  },
  {
    "name": "SunSpark Yoga",
    "website": "sunsparkyoga.com",
    "email": "info@sunsparkyoga.com",
    "city": "Orange",
    "phone": "714-786-5994",
    "state":"California"
  },
  {
    "name": "Yoga 4 Mankind",
    "website": "yogaformankind.com",
    "email": "info@yogaformankind.com",
    "city": "Orcutt",
    "phone": "805-680-6542",
    "state":"California"
  },
  {
    "name": "Blossom Birth and Family",
    "website": "blossombirthandfamily.org",
    "email": "blossom@blossombirthandfamily.org",
    "city": "Palo Alto",
    "phone": "650-321-2326",
    "state":"California"
  },
  {
    "name": "Samyama Yoga Center",
    "website": "samyamayogacenter.com",
    "email": "info@samyamayogacenter.com",
    "city": "Palo Alto",
    "phone": "650-320-9262",
    "state":"California"
  },
  {
    "name": "Avalon Yoga International",
    "website": "avalonyoga.com",
    "email": "info@avalonyoga.com",
    "city": "Palo Alto",
    "phone": "650-324-2517",
    "state":"California"
  },
  {
    "name": "YogaSource",
    "website": "yogasource.com",
    "email": "info@yogasource.com",
    "city": "Palo Alto",
    "phone": "650-328-9642",
    "state":"California"
  },
  {
    "name": "Yoga House",
    "website": "yogahouse.com",
    "email": "info@yogahouse.com",
    "city": "Pasadena",
    "phone": "626-403-3961",
    "state":"California"
  },
  {
    "name": "The Luma Center",
    "website": "thelumacenter.com",
    "email": "info@thelumacenter.com",
    "city": "Petaluma",
    "phone": "707-583-9156",
    "state":"California"
  },
  {
    "name": "Yoga in Quartz Hill",
    "website": "yogainquartzhill.com",
    "email": "vidavis@live.com",
    "city": "Quartz Hill",
    "phone": "661-339-0638",
    "state":"California"
  },
  {
    "name": "Inner Evolution Yoga",
    "website": "innerevolutionyoga.com",
    "email": "contact@innerevolutionyoga.com",
    "city": "Redlands",
    "phone": "909-798-2244",
    "state":"California"
  },
  {
    "name": "Fondin Wellness",
    "website": "fondinwellness.com",
    "email": "michellefondinauthor@gmail.com",
    "city": "Redondo Beach",
    "phone": "571-265-4559",
    "state":"California"
  },
  {
    "name": "Reveille Yoga",
    "website": "reveillelife.com",
    "email": "Info@reveillelife.com",
    "city": "Riverside",
    "phone": "951-529-0346",
    "state":"California"
  },
  {
    "name": "Source Studio",
    "website": "sourceyogaca.com",
    "email": "sourceyogaca@yahoo.com",
    "city": "Riverside",
    "phone": "951-500-5009",
    "state":"California"
  },
  {
    "name": "CorePower Yoga",
    "website": "corepoweryoga.com",
    "email": "social@corepoweryoga.com",
    "city": "Roseville",
    "phone": "866-441-9642",
    "state":"California"
  },
  {
    "name": "Violet Yoga Fitness",
    "website": "violetyogafitness.com",
    "email": "violetyogafitness@gmail.com",
    "city": "Rowland Heights",
    "phone": "626-678-3080",
    "state":"California"
  },
  {
    "name": "Birth Wisdom Yoga",
    "website": "BirthWisdomYoga.com",
    "email": "Julia@BirthWisdomYoga.com",
    "city": "Sacramento",
    "phone": "916-337-7869",
    "state":"California"
  },
  {
    "name": "One Flow Yoga",
    "website": "oneflowyoga.com",
    "email": "info@oneflowyoga.com",
    "city": "Sacramento",
    "phone": "916-842-6082",
    "state":"California"
  },
  {
    "name": "Asha Yoga",
    "website": "ashayoga.com",
    "email": "frontdesk@ashayoga.com",
    "city": "Sacramento",
    "phone": "916-443-6535",
    "state":"California"
  },
  {
    "name": "Yoga Salinas",
    "website": "yogasalinas.com",
    "email": "info@yogasalinas.com",
    "city": "Salinas",
    "phone": "831-747-7176",
    "state":"California"
  },
  {
    "name": "Pomegranate",
    "website": "pomcenter.com",
    "email": "info@pomcenter.com",
    "city": "San Anselmo",
    "phone": "415-785-3173",
    "state":"California"
  },
  {
    "name": "Yoga Jai Ma",
    "website": "yogajaima.com",
    "email": "info@yogajaima.com",
    "city": "San Diego",
    "phone": "858-859-5569",
    "state":"California"
  },
  {
    "name": "Hapa Yoga",
    "website": "hapayoga.com",
    "email": "tina@hapayoga.com",
    "city": "San Diego",
    "phone": "619-309-6732",
    "state":"California"
  },
  {
    "name": "The Little Yoga Studio",
    "website": "littleyoga.studio",
    "email": "ems@littleyoga.studio",
    "city": "San Diego",
    "phone": "619-544-9642",
    "state":"California"
  },
  {
    "name": "United Yoga Studio",
    "website": "unitedyogastudio.com",
    "email": "namaste@unitedyogastudio.com",
    "city": "San Dimas",
    "phone": "909-451-9750",
    "state":"California"
  },
  {
    "name": "Yoga Tree Valencia",
    "website": "yogatreesf.com",
    "email": "info@yogatreesf.com",
    "city": "San Francisco",
    "phone": "415-647-9707",
    "state":"California"
  },
  {
    "name": "The Mindful Body",
    "website": "themindfulbody.com",
    "email": "maile@themindfulbody.com",
    "city": "San Francisco",
    "phone": "415-931-2639",
    "state":"California"
  },
  {
    "name": "Yoga Garden SF",
    "website": "yogagardensf.com",
    "email": "david@yogagardensf.com",
    "city": "San Francisco",
    "phone": "415-552-9644",
    "state":"California"
  },
  {
    "name": "Thriveability Yoga",
    "website": "thriveabilitysf.com",
    "email": "info@thriveabilitysf.com",
    "city": "San Francisco",
    "phone": "415-566-0888",
    "state":"California"
  },
  {
    "name": "Integral Yoga Institute San Francisco",
    "website": "integralyogasf.org",
    "email": "mail@integralyogasf.org",
    "city": "San Francisco",
    "phone": "415-821-1117",
    "state":"California"
  },
  {
    "name": "Willow Glen Yoga",
    "website": "willowglenyoga.com",
    "email": "info@willowglenyoga.com",
    "city": "San Jose",
    "phone": "408-289-9642",
    "state":"California"
  },
  {
    "name": "Balance Yoga Center",
    "website": "BalanceYogaCenterOnline.com",
    "email": "Info@BalanceYogaCenterOnline.com",
    "city": "San Jose",
    "phone": "408-362-1139",
    "state":"California"
  },
  {
    "name": "Prenatal Yoga San Marcos",
    "website": "lgbyoga.blogspot.com",
    "email": "Not Found",
    "city": "San Marcos",
    "phone": "760-889-2781",
    "state":"California"
  },
  {
    "name": "melā.yoga",
    "website": "mela.yoga",
    "email": "info@mela.yoga",
    "city": "San Mateo",
    "phone": "650-762-5510",
    "state":"California"
  },
  {
    "name": "Nandi Yoga",
    "website": "nandiyoga.com",
    "email": "info@nandiyoga.com",
    "city": "San Mateo",
    "phone": "650-343-9642",
    "state":"California"
  },
  {
    "name": "The Yoga Fusion",
    "website": "theyogafusion.com",
    "email": "TheYogaFusion@yahoo.com",
    "city": "San Ramon",
    "phone": "925-378-2530",
    "state":"California"
  },
  {
    "name": "Santa Barbara Yoga Center",
    "website": "santabarbarayogacenter.com",
    "email": "info@santabarbarayogacenter.com",
    "city": "Santa Barbara",
    "phone": "805-965-6045",
    "state":"California"
  },
  {
    "name": "Just Breathe Yoga",
    "website": "justbreatheyogarivermark.com",
    "email": "angie@justbreatheyogarivermark.com",
    "city": "Santa Clara",
    "phone": "415-517-2310",
    "state":"California"
  },
  {
    "name": "Yoga Yoga",
    "website": "yogayogaonline.com",
    "email": "grow@yogayogaonline.com",
    "city": "Santa Clarita",
    "phone": "661-222-7777",
    "state":"California"
  },
  {
    "name": "Two Hearts Yoga",
    "website": "twoheartsyoga.com",
    "email": "info@twoheartsyoga.com",
    "city": "Sherman Oaks",
    "phone": "818-501-9642",
    "state":"California"
  },
  {
    "name": "Seed to Sprout Yoga",
    "website": "seedtosproutyoga.com",
    "email": "katiecottieryoga@gmail.com",
    "city": "Thousand Oaks",
    "phone": "805-409-7312",
    "state":"California"
  },
  {
    "name": "Flow Yoga and Wellness Studio",
    "website": "flowyogastudios.com",
    "email": "info@flowyogastudios.com",
    "city": "Tracy",
    "phone": "209-830-0405",
    "state":"California",
  },
  {
    "name": "I Am Yoga Wellness Studio",
    "website": "iamyogastudio.com",
    "email": "info@iamyogastudio.com",
    "city": "Turlock",
    "phone": "209-667-8100",
    "state":"California"
  },
  {
    "name": "Yoga Mendocino",
    "website": "yogamendocino.org",
    "email": "yomo@pacific.net",
    "city": "Ukiah",
    "phone": "707-462-2580",
    "state":"California"
  },
  {
    "name": "Worlds Yoga Union City",
    "website": "worldsyoga.com",
    "email": "info@worldsyoga.com",
    "city": "Union City",
    "phone": "510-441-9642",
    "state":"California"
  },
  {
    "name": "YogaMaya Hot Yoga & Wellness Studio",
    "website": "yogamayastudio.com",
    "email": "hello@yogamayastudio.com",
    "city": "Vacaville",
    "phone": "707-455-7750",
    "state":"California"
  },
  {
    "name": "Yoga Jones",
    "website": "yogajones.com",
    "email": "info@yogajones.com",
    "city": "Ventura",
    "phone": "805-643-5669",
    "state":"California"
  },
  {
    "name": "The Space Yoga Studio",
    "website": "thespacevisalia.com",
    "email": "thespacevisalia@gmail.com",
    "city": "Visalia",
    "phone": "323-683-7538",
    "state":"California"
  },
  {
    "name": "Oak Street Studios Fitness",
    "website": "oakstreetstudiosfitness.com",
    "email": "hello@oakstreetstudiosfitness.com",
    "city": "Visalia",
    "phone": "559-901-5402",
    "state":"California"
  },
  {
    "name": "Indigo Yoga, Pilates & Cycling",
    "website": "myindigoyoga.com",
    "email": "info@myindigoyoga.com",
    "city": "Walnut Creek",
    "phone": "925-979-9642",
    "state":"California"
  },
  {
    "name": "Yoganette Yoga Studio",
    "website": "yoganette.com",
    "email": "info@yoganette.com",
    "city": "West Covina",
    "phone": "626-965-4000",
    "state":"California"
  },
  {
    "name": "Yoga by the Sea : The Soul Center",
    "website": "thesoulcenteryoga.net",
    "email": "satyasnr@icloud.com",
    "city": "West Hills",
    "phone": "310-717-5733",
    "state":"California"
  },
  {
    "name": "Yoga Sol Studio",
    "website": "yogasolstudio.com",
    "email": "info@yogasolstudio.com",
    "city": "Yorba Linda",
    "phone": "714-996-0088",
    "state":"California"
  },
  {
    "name": "PureJoy Yoga",
    "website": "yogayubacity.com",
    "email": "purejoyyogayc@gmail.com",
    "city": "Yuba City",
    "phone": "530-673-9642",
    "state":"California"
  }
]
